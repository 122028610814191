// font families
$big-font: "Space Grotesk", sans-serif;
$small-font: "Noto Sans Georgian", sans-serif;

// font sizes
$h1-size: 7rem;
$h2-size: 6rem;
$h4-size: 2.2rem;
$text-normal: 2rem;
$text-mini: 1.8rem;
