@use "../global/" as *;

nav {
  font-family: $big-font;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 200;

  a {
    color: $color-white;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  max-width: 120rem;
  margin: 0 auto;
  font-size: $text-normal;
  padding: 3rem 2rem;
  align-items: center;
  color: $color-white;

  p {
    font-size: 3rem;
    font-weight: 700;
    cursor: pointer;
  }

  ul {
    display: flex;
    gap: 4rem;
    list-style: none;

    @media (max-width: 750px) {
      display: none;
    }

    a {
      color: $color-white;
      font-weight: 700;

      &:hover {
        transition: all 0.3s;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        background-image: $color-blue-purp;
      }
    }
  }

  span {
    display: flex;
    gap: 2rem;

    i {
      cursor: pointer;

      @media (max-width: 750px) {
        display: none;
      }

      &:hover {
        transition: all 0.3s;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        background-image: $color-blue-purp;
      }
    }
  }
}

.hamburger-menu {
  display: none;
  font-size: 2.7rem;

  @media (max-width: 750px) {
    display: flex !important;
  }
}

.sticky-nav {
  background-color: $bg-black;
  box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.16);
  position: fixed;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background-color: $color-white;
  z-index: 9999999;
  color: $bg-black;
  font-size: 3rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;

  ul {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    list-style: none;

    a {
      color: $bg-black;
      font-weight: 600;

      &:hover {
        transition: all 0.3s;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        background-image: $color-blue-purp;
      }
    }
  }
}

.mobile-up {
  left: 0;
}

.close-mobile {
  position: absolute;
  top: 3rem;
  right: 3rem;
  cursor: pointer;

  &:hover {
    transition: all 0.3s;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: $color-blue-purp;
  }
}
