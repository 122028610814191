@use "../global/" as *;

.why-section {
  background-color: $bg-black;
  padding: 10rem;

  @media (max-width: 700px) {
    padding: 0rem;
  }
}

.choose-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    font-size: $h1-size;
    color: $color-white;
    text-transform: uppercase;
    font-family: $small-font;
    margin-bottom: 10rem;

    span {
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      background-image: $color-blue-purp;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    color: white;
    font-size: 3rem;
    width: 100%;
    height: auto;
    gap: 2rem;

    @media (max-width: 1100px) {
      grid-template-columns: 1fr;
    }

    &__1,
    &__3 {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }

    &__2 {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 85%;

        @media (max-width: 1100px) {
          width: 30%;
        }

        @media (max-width: 800px) {
          width: 45%;
        }
      }
    }
  }
}

.choose-box {
  border-radius: 2.4rem;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 2.4rem;
  border: 1px solid rgba(131, 131, 131, 0.25);
  display: flex;
  justify-content: center;
  gap: 4rem;
  font-family: $small-font;
  width: 100%;

  @media (max-width: 400px) {
    gap: 2rem;
  }

  h4 {
    font-size: 2.5rem;
    text-transform: uppercase;
    font-family: $small-font;
    text-align: left;
  }

  p {
    font-size: $text-mini;
    text-align: left;
    margin-top: 1rem;
    color: #e7e6e8;
  }

  i {
    background: $color-blue-purp;
    display: flex;
    width: 6rem;
    height: 6rem;
    padding: 1.5rem;
    align-items: center;
    border-radius: 1.6rem;
  }
}
